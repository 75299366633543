exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contatti-index-js": () => import("./../../../src/pages/contatti/index.js" /* webpackChunkName: "component---src-pages-contatti-index-js" */),
  "component---src-pages-contatti-success-js": () => import("./../../../src/pages/contatti/success.js" /* webpackChunkName: "component---src-pages-contatti-success-js" */),
  "component---src-pages-dato-cms-chi-siamo-slug-js": () => import("./../../../src/pages/{DatoCmsChiSiamo.slug}.js" /* webpackChunkName: "component---src-pages-dato-cms-chi-siamo-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-cookie-policy-js": () => import("./../../../src/pages/privacy/cookie-policy.js" /* webpackChunkName: "component---src-pages-privacy-cookie-policy-js" */),
  "component---src-pages-privacy-dato-cms-privacy-slug-js": () => import("./../../../src/pages/privacy/{DatoCmsPrivacy.slug}.js" /* webpackChunkName: "component---src-pages-privacy-dato-cms-privacy-slug-js" */),
  "component---src-pages-privacy-privacy-policy-js": () => import("./../../../src/pages/privacy/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-privacy-policy-js" */),
  "component---src-pages-progetti-dato-cms-progetto-slug-js": () => import("./../../../src/pages/progetti/{DatoCmsProgetto.slug}.js" /* webpackChunkName: "component---src-pages-progetti-dato-cms-progetto-slug-js" */),
  "component---src-pages-progetti-index-js": () => import("./../../../src/pages/progetti/index.js" /* webpackChunkName: "component---src-pages-progetti-index-js" */),
  "component---src-pages-servizi-dato-cms-servizio-slug-js": () => import("./../../../src/pages/servizi/{DatoCmsServizio.slug}.js" /* webpackChunkName: "component---src-pages-servizi-dato-cms-servizio-slug-js" */),
  "component---src-pages-thirdblog-dato-cms-articolo-slug-js": () => import("./../../../src/pages/thirdblog/{DatoCmsArticolo.slug}.js" /* webpackChunkName: "component---src-pages-thirdblog-dato-cms-articolo-slug-js" */),
  "component---src-pages-thirdblog-index-js": () => import("./../../../src/pages/thirdblog/index.js" /* webpackChunkName: "component---src-pages-thirdblog-index-js" */)
}

